/* 
.wrap {
	margin: 0 24px;
}
@media only screen and (min-width: 760px) {
	.wrap {
		margin: 0 50px;
	}
}
@media only screen and (min-width: 1280px) {
	.wrap {
		margin: 0 auto;
		width: 1180px;
	}
}
@media only screen and (min-width: 1900px) {
	.wrap {
		width: 1640px;
	}
}
 */

.site-footer {
	text-align: center;
	font-weight: 500;
	font-size: 0.8rem;
	margin-top: 180px;
	padding: 30px 0;
}