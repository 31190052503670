.some-icons {
  margin-top: 20px;

  .some-icon {
    transform-origin: 50% 50%;
    transform: scale(1);
    transition: all .2s ease-in-out;

    &:hover {
      transform-origin: 50% 50%;
      transform: scale(1.2);
      transition: all .2s ease-out;
    }
  }
}

.some-icon {
  display: inline-block;
  width: 80px;
  height: 80px;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 80px;
    height: 80px;
    background-size: auto 40px;
    background-repeat: no-repeat;
    background-position: center center;
  }

}


.icon-fb:before {
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.71 44.04'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1-2'%3E%3Cpath d='M7.9,26.82H1.28V19H7.9V12.8c0-3.27.9-5.81,2.75-7.62A10,10,0,0,1,18,2.49a43.32,43.32,0,0,1,6,.34v7H19.86a4,4,0,0,0-3.18,1A4.41,4.41,0,0,0,16,13.58V19H23.3l-1,7.82H16V46.52H7.88Z' transform='translate(-1.28 -2.48)' style='fill:%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.icon-ig:before {
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 44.04'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1-2'%3E%3Cpath d='M46.83,15.8q.11,2.76.11,9.14t-.15,9.19A18.75,18.75,0,0,1,45.91,39a11.51,11.51,0,0,1-7,7,19.1,19.1,0,0,1-4.86.88q-2.8.13-9.18.15t-9.19-.15a16.28,16.28,0,0,1-4.83-1,10.54,10.54,0,0,1-4.26-2.6A11.49,11.49,0,0,1,3.92,39a18.68,18.68,0,0,1-.83-4.86c-.1-1.87-.15-4.94-.15-9.19s.05-7.31.15-9.18a18.35,18.35,0,0,1,.83-4.88,11.46,11.46,0,0,1,7-7,18.76,18.76,0,0,1,4.88-.83q2.79-.15,9.18-.15t9.18.15A18.61,18.61,0,0,1,39,3.92a11.5,11.5,0,0,1,7,7A19.2,19.2,0,0,1,46.83,15.8ZM42.12,37.9a20.16,20.16,0,0,0,.79-5.29c.06-1.44.1-3.47.1-6.09V23.37c0-2.68,0-4.72-.1-6.09a19.43,19.43,0,0,0-.79-5.3A7,7,0,0,0,37.9,7.77,19.37,19.37,0,0,0,32.61,7c-1.45,0-3.47-.09-6.09-.09H23.37q-3.93,0-6.1.09a20.59,20.59,0,0,0-5.29.8A7,7,0,0,0,7.77,12a19.44,19.44,0,0,0-.8,5.3c-.06,1.44-.09,3.48-.1,6.09v3.14c0,2.63,0,4.65.1,6.09a20.17,20.17,0,0,0,.8,5.29A7.43,7.43,0,0,0,12,42.12a20.57,20.57,0,0,0,5.29.78q2.18.1,6.1.1h3.14q4,0,6.09-.1a19.35,19.35,0,0,0,5.29-.78A7.42,7.42,0,0,0,42.12,37.9ZM24.94,13.64a11,11,0,0,1,5.65,1.53A11.28,11.28,0,1,1,15.17,30.58a11.3,11.3,0,0,1,4.12-15.42A11,11,0,0,1,24.94,13.64Zm0,18.67a7.37,7.37,0,1,0-7.37-7.37A7.38,7.38,0,0,0,24.94,32.31ZM39.37,13.16a2.65,2.65,0,1,0-5.29,0A2.68,2.68,0,0,0,34.85,15a2.55,2.55,0,0,0,1.88.78A2.35,2.35,0,0,0,38.54,15,3.06,3.06,0,0,0,39.37,13.16Z' transform='translate(-2.94 -2.94)' style='fill:%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.icon-fb.dark:before {
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.71 44.04'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1-2'%3E%3Cpath d='M7.9,26.82H1.28V19H7.9V12.8c0-3.27.9-5.81,2.75-7.62A10,10,0,0,1,18,2.49a43.32,43.32,0,0,1,6,.34v7H19.86a4,4,0,0,0-3.18,1A4.41,4.41,0,0,0,16,13.58V19H23.3l-1,7.82H16V46.52H7.88Z' transform='translate(-1.28 -2.48)' style='fill:%231a2a36'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.icon-ig.dark:before {
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 44 44.04'%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Layer_1-2' data-name='Layer 1-2'%3E%3Cpath d='M46.83,15.8q.11,2.76.11,9.14t-.15,9.19A18.75,18.75,0,0,1,45.91,39a11.51,11.51,0,0,1-7,7,19.1,19.1,0,0,1-4.86.88q-2.8.13-9.18.15t-9.19-.15a16.28,16.28,0,0,1-4.83-1,10.54,10.54,0,0,1-4.26-2.6A11.49,11.49,0,0,1,3.92,39a18.68,18.68,0,0,1-.83-4.86c-.1-1.87-.15-4.94-.15-9.19s.05-7.31.15-9.18a18.35,18.35,0,0,1,.83-4.88,11.46,11.46,0,0,1,7-7,18.76,18.76,0,0,1,4.88-.83q2.79-.15,9.18-.15t9.18.15A18.61,18.61,0,0,1,39,3.92a11.5,11.5,0,0,1,7,7A19.2,19.2,0,0,1,46.83,15.8ZM42.12,37.9a20.16,20.16,0,0,0,.79-5.29c.06-1.44.1-3.47.1-6.09V23.37c0-2.68,0-4.72-.1-6.09a19.43,19.43,0,0,0-.79-5.3A7,7,0,0,0,37.9,7.77,19.37,19.37,0,0,0,32.61,7c-1.45,0-3.47-.09-6.09-.09H23.37q-3.93,0-6.1.09a20.59,20.59,0,0,0-5.29.8A7,7,0,0,0,7.77,12a19.44,19.44,0,0,0-.8,5.3c-.06,1.44-.09,3.48-.1,6.09v3.14c0,2.63,0,4.65.1,6.09a20.17,20.17,0,0,0,.8,5.29A7.43,7.43,0,0,0,12,42.12a20.57,20.57,0,0,0,5.29.78q2.18.1,6.1.1h3.14q4,0,6.09-.1a19.35,19.35,0,0,0,5.29-.78A7.42,7.42,0,0,0,42.12,37.9ZM24.94,13.64a11,11,0,0,1,5.65,1.53A11.28,11.28,0,1,1,15.17,30.58a11.3,11.3,0,0,1,4.12-15.42A11,11,0,0,1,24.94,13.64Zm0,18.67a7.37,7.37,0,1,0-7.37-7.37A7.38,7.38,0,0,0,24.94,32.31ZM39.37,13.16a2.65,2.65,0,1,0-5.29,0A2.68,2.68,0,0,0,34.85,15a2.55,2.55,0,0,0,1.88.78A2.35,2.35,0,0,0,38.54,15,3.06,3.06,0,0,0,39.37,13.16Z' transform='translate(-2.94 -2.94)' style='fill:%231a2a36'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

header {
  .some-icon a {
    opacity: 0;
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    width: 30px;
    height: 26px;

    &:hover {
      border: none !important;
    }
  }

  .some-icon {
    position: relative;
    width: 26px;
    height: 26px;

    &:hover {
      opacity: 0.6;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 26px;
      background-size: auto 100%;
    }
  }

  @media only screen and (max-width: 999px) {
    .some-icon {
      margin-top: 10px !important;

      &:before,
      a {
        left: -5px;
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    .some-icon a {
      width: 20px;
      height: 16px
    }

    .some-icon {
      position: absolute;
      top: 36px;
      width: 16px;
      height: 16px;

      &:before {
        width: 20px;
        height: 16px;
      }
    }

    .icon-fb {
      right: 60px;
    }
    .icon-ig {
      right: 30px;
    }
  }
}