.gform_wrapper {
  .gform_button {
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    padding: 12px 30px;
    color: white;
    background-color: #1b2a36;
  }

  input,
  textarea {
    border-radius: 0;
    border: 1px solid #99a5ae;
  }
}

.gform_confirmation_message {
  padding: 60px 0 150px;
  font-size: 1.5rem;
  font-family: Lyon Display;
  line-height: 1.25em;
  font-weight: 700;
  text-align: center;
}
