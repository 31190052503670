.slider .slick-slide {
  .art-fullpage__content__headline-graphik {
    font-weight: 100;
    font-family: "ITCAvantGardePro-Bk";
  }

  svg {
    margin-top: -60px;
    width: 160px;
    height: auto;
  }

  &:last-of-type {
    .art-fullpage__content__headline-graphik {
      margin-top: -40px;
      text-align: left;
      font-family: "Neutraface Title";
      font-size: 2.5rem;
      line-height: 1em;
      letter-spacing: 0.03em;
      font-weight: 500;
      text-transform: none;

      @media only screen and (min-width: 760px) {
        font-size: 3rem;
      }
    }
  }
}
