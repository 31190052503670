/* 
.wrap {
	margin: 0 24px;
}
@media only screen and (min-width: 760px) {
	.wrap {
		margin: 0 50px;
	}
}
@media only screen and (min-width: 1280px) {
	.wrap {
		margin: 0 auto;
		width: 1180px;
	}
}
@media only screen and (min-width: 1900px) {
	.wrap {
		width: 1640px;
	}
}
*/


.infobox {
	position: relative;
	width: 100%;
	z-index: 100;
}

.infobox__toggler {
	position: relative;
	display: inline-block;
	font-weight: 700;
	cursor: pointer;
	transition: color 0.2s ease-in-out;
	-webkit-transition: color 0.2s ease-in-out;
	padding-left: 30px;
}

.infobox__toggler:hover {
	color: #48a8dd;
}

.infobox__toggler:before {
  display: block;
	content: "";
	position: absolute;
	top: 2px;
	left: 0;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	border: 1px solid #a3a3a3;
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpolygon points='10 4.5 5.5 4.5 5.5 0 4.5 0 4.5 4.5 0 4.5 0 5.5 4.5 5.5 4.5 10 5.5 10 5.5 5.5 10 5.5 10 4.5' style='fill:%23a3a3a3'/%3E%3C/svg%3E");
	background-size: 9px 9px;
	background-position: center center;
	background-repeat: no-repeat;
}

.infobox__content {
	display: none;
	position: absolute;
	top: -1px;
	left: -1px;
	border: 1px solid rgba(0,0,0,0.1);
	background-color: #fff;
	padding: 0 12px;
	-webkit-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.12);
	-moz-box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.12);
	box-shadow: 2px 3px 10px 0px rgba(0,0,0,0.12);
	border-radius: 3px;
	-moz-border-radius: 3px;
}

.infobox__content__close-btn {
	position: absolute;
	text-indent: -9999px;
	margin: 0;
	padding: 0;
	top: 8px;
	right: 8px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	border: none;
	background-color: #1a2a36;
	outline: none;
  background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' width='8.4' height='8.4' viewBox='0 0 8.4 8.4'%3E%3Cpolygon points='8.4 1.4 7 0 4.2 2.8 1.4 0 0 1.4 2.8 4.2 0 7 1.4 8.4 4.2 5.6 7 8.4 8.4 7 5.6 4.2 8.4 1.4' style='fill:%23fff'/%3E%3C/svg%3E");
	background-position: center center;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	cursor: pointer;
	opacity: 0.5;
	transition: opacity 0.2s ease-in-out;
	-webkit-transition: opacity 0.2s ease-in-out;
}

.infobox__content__close-btn:hover {
	opacity: 1;
}

.infobox table {
	border-collapse: collapse;
	border-spacing: 0;
	line-height: 1.5em;
	font-size: 0.8em;
	margin: 1em 0;
	width: 100%;
}

.infobox table thead th {
    background-color: #dbdee2;
    border: solid 1px #c4cad1;
    padding: 0.6em;
    text-align: left;
    vertical-align: top;
    color: #000;
}

.infobox table tbody td {
    border: solid 1px #c4cad1;
    padding: 0.6em;
}

@media only screen and (min-width: 1900px) {
	.infobox__content {
		padding: 0 20px 10px 20px;
	}
}