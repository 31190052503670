body.noscroll {
  @media only screen and (max-width: 999px) {
    overflow: hidden;
  }
}

p {
  img {
    max-width: 100%;
    height: auto;
  }
}

form.password-form {
  text-align: center;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 48%;

  label {
    display: none;
  }

  input.password-box {
    border-radius: 0;
    border: 1px solid #cbcbcb;
    padding: 9px 8px;
    margin-right: -5px;
  }

  input.password-btn {
    background-color: #1A2A36;
    border: none;
    color: #fff;
    margin-bottom: 10px;
    padding: 10px 40px;
  }
}
