@font-face {
  font-family: 'ITCAvantGardePro-Bk';
  src: url('../fonts/ITCAvantGardePro-Bk.woff2') format('woff2'), url('../fonts/ITCAvantGardePro-Bk.woff') format('woff');
}
@font-face {
  font-family: 'AvantGardeGothicITCW02Bd';
  src: url('../fonts/AvantGardeGothicITCW02Bd.woff2') format('woff2'), url('../fonts/AvantGardeGothicITCW02Bd.woff') format('woff');
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "ITCAvantGardePro-Bk";
  font-weight: 400;
  font-size: 16px;
  line-height: 1.8em;
  letter-spacing: 0.2px;
}

.art-cols,
.art-col-1 {
  h1 {
    font-family: "AvantGardeGothicITCW02Bd";
    font-size: 2.5rem;
    line-height: 1.25em;
    font-weight: 700;
  }

  h2 {
    font-family: "AvantGardeGothicITCW02Bd";
    font-size: 2.2rem;
    line-height: 1.25em;
    font-weight: 700;
  }

  h3 {
    font-family: "AvantGardeGothicITCW02Bd";
    font-size: 1.8rem;
    line-height: 1.25em;
    font-weight: 700;
  }

  h4 {
    font-size: 1.17em;
  }
}

/* @font-face {
  font-family: 'Graphik Web';
  src: 
    url('../fonts/Graphik-Light.woff2') format('woff2'),
    url('../fonts/Graphik-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik Web';
  src: 
    url('../fonts/Graphik-LightItalic.woff2') format('woff2'),
    url('../fonts/Graphik-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik Web';
  src: 
    url('../fonts/Graphik-Regular.woff2') format('woff2'),
    url('../fonts/Graphik-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik Web';
  src: 
    url('../fonts/Graphik-RegularItalic.woff2') format('woff2'),
    url('../fonts/Graphik-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik Web';
  src: 
    url('../fonts/Graphik-Medium.woff2') format('woff2'),
    url('../fonts/Graphik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Graphik Web';
  src: 
    url('../fonts/Graphik-MediumItalic.woff2') format('woff2'),
    url('../fonts/Graphik-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Lyon Text';
  src: url('../fonts/LyonText-Regular-Web.eot');
  src: url('../fonts/LyonText-Regular-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/LyonText-Regular-Web.woff') format('woff'),
    url('../fonts/LyonText-Regular-Web.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Lyon Text';
  src: url('../fonts/LyonText-RegularItalic-Web.eot');
  src: url('../fonts/LyonText-RegularItalic-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/LyonText-RegularItalic-Web.woff') format('woff'),
    url('../fonts/LyonText-RegularItalic-Web.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Lyon Text';
  src: url('../fonts/LyonText-Bold-Web.eot');
  src: url('../fonts/LyonText-Bold-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/LyonText-Bold-Web.woff') format('woff'),
    url('../fonts/LyonText-Bold-Web.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Lyon Text';
  src: url('../fonts/LyonText-BoldItalic-Web.eot');
  src: url('../fonts/LyonText-BoldItalic-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/LyonText-BoldItalic-Web.woff') format('woff'),
    url('../fonts/LyonText-BoldItalic-Web.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Lyon Display';
  src: url('../fonts/LyonDisplay-Medium-Web.eot');
  src: url('../fonts/LyonDisplay-Medium-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/LyonDisplay-Medium-Web.woff') format('woff'),
    url('../fonts/LyonDisplay-Medium-Web.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Lyon Display';
  src: url('../fonts/LyonDisplay-MediumItalic-Web.eot');
  src: url('../fonts/LyonDisplay-MediumItalic-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/LyonDisplay-MediumItalic-Web.woff') format('woff'),
    url('../fonts/LyonDisplay-MediumItalic-Web.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Lyon Display';
  src: url('../fonts/LyonDisplay-Bold-Web.eot');
  src: url('../fonts/LyonDisplay-Bold-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/LyonDisplay-Bold-Web.woff') format('woff'),
    url('../fonts/LyonDisplay-Bold-Web.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Lyon Display';
  src: url('../fonts/LyonDisplay-BoldItalic-Web.eot');
  src: url('../fonts/LyonDisplay-BoldItalic-Web.eot?#iefix') format('embedded-opentype'),
    url('../fonts/LyonDisplay-BoldItalic-Web.woff') format('woff'),
    url('../fonts/LyonDisplay-BoldItalic-Web.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
} 

html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Graphik Web";
  font-weight: 300;
  font-size: 15px;
  line-height: 1.8em;
}

.art-cols,
.art-col-1 {
  h1 {
    font-family: "Lyon Display";
    font-size: 2.7rem;
    line-height: 1.25em;
    font-weight: 700;
  }

  h2 {
    font-family: "Lyon Display";
    font-size: 2.5rem;
    line-height: 1.25em;
    font-weight: 700;
  }

  h3 {
    font-family: "Lyon Display";
    font-size: 2rem;
    line-height: 1.25em;
    font-weight: 700;
  }

  h4 {
    font-size: 1.17em;
  }
}

*/