//@import "bootstrap/scss/bootstrap"; // Load Bootstrap from node_modules

@import "slick-carousel/slick/slick.scss";


$hamburger-padding-x           : 20px !default;
$hamburger-padding-y           : 20px !default;
$hamburger-layer-width         : 25px !default;
$hamburger-layer-height        : 1px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : #1a2a36 !default;
$hamburger-layer-border-radius : 0px !default;

$hamburger-types: (
  spin
) !default;

@import "hamburgers/_sass/hamburgers/hamburgers";

//@import "reset";
@import "common/global";

@import "modules/base";
@import "modules/typography";
@import "modules/header";
@import "modules/art-cols";
@import "modules/art-fullpage";
@import "modules/art-intro";
@import "modules/art-text";
@import "modules/infobox";
@import "modules/site-footer";
@import "modules/site-header";
@import "modules/slider";
@import "modules/divider";
@import "modules/empty-space";
@import "modules/fullwidth-img";
@import "modules/gform";
@import "modules/some-icons";
@import "modules/video";
@import "modules/herovideo";
