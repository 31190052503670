.herovideo {
  width: 100%;
  height: auto;
  video {
    right: 0;
    bottom: 0;
    min-height: 100px;
    height: auto;
    width: 100% !important;
    background: transparent;
    border-bottom: 1px solid transparent;
  }
}
