.video-wrapper{
  .video {
    display: flex;
    align-items: center;
    justify-content: center;
    video {
      width: 100%;
      height: auto;
      max-height: 600px;
    }
  }
}
