.empty-space {
  height: 60px;

  @media only screen and (min-width: 600px) {
    height: 100px;
  }

  @media only screen and (min-width: 1280px) {
    height: 120px;
  }

  @media only screen and (min-width: 1900px) {
    height: 150px;
  }
}
