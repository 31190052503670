/* 
.wrap {
  margin: 0 24px;
}
@media only screen and (min-width: 760px) {
  .wrap {
    margin: 0 50px;
  }
}
@media only screen and (min-width: 1280px) {
  .wrap {
    margin: 0 auto;
    width: 1180px;
  }
}
@media only screen and (min-width: 1900px) {
  .wrap {
    width: 1640px;
  }
}
*/

#intro,
#label {
  text-align: center;
  font-size: 1.3rem;
  line-height: 1.6em;
  font-weight: 300;
  margin: 60px 24px;
}

@media only screen and (min-width: 600px) {
  #intro,
  #label {
    margin: 100px 50px;
  }
}

@media only screen and (min-width: 1000px) {
  #intro,
  #label {
    line-height: 1.7em;
    margin: 100px auto;
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 1280px) {
  #intro,
  #label {
    margin: 150px auto;
  }
}

@media only screen and (min-width: 1900px) {
  #intro,
  #label {
    margin: 190px auto;
  }
}
