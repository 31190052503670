html {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	border: 0;
}

body {
	width: 100%;
	height: 100%;
	position: relative;
	background-color: #fff;
	color: #1A2A36;
	margin: 0;
	padding: 0;
	border: 0;

	padding-top: 60px;
	@media only screen and (min-width: 1000px) {
	  padding-top: 100px;
	}

}

figure {
	margin: 0;
	padding: 0;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

a {
	text-decoration: none;
	color: #1A2A36;
}

.link-arrow {
	font-size: 1.25rem;
	line-height: 1.6em;
	font-weight: 400;
	display: inline-block;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28.447' height='27.952' viewBox='0 0 28.447 27.952'%3E%3Cg id='Group_205' data-name='Group 205' transform='translate(-1035 1035.745) rotate(-90)'%3E%3Cline id='Line_5' data-name='Line 5' y2='27.525' transform='translate(1021.672 1035)' fill='none' stroke='%231a2a36' stroke-width='2'/%3E%3Cpath id='Path_421' data-name='Path 421' d='M-3969,5108.5l13.269,13.27,13.269-13.27' transform='translate(4977.495 -4059.737)' fill='none' stroke='%231a2a36' stroke-width='2'/%3E%3C/g%3E%3C/svg%3E");
	background-position: center left;
	background-repeat: no-repeat;
	background-size: auto 1em;
	padding-left: 3em;
	max-width: 25rem;
	transition: opacity 0.4s ease-in-out;
	-webkit-transition: opacity 0.4s ease-in-out;
}

.link-arrow:hover {
	opacity: 0.5;
}

.wrap {
	margin: 0 24px;
}


::-moz-selection {
	background: #c0c0c0;
}

::selection {
	background: #c0c0c0;
}

@media only screen and (min-width: 760px) {
	.wrap {
		margin: 0 50px;
	}
}
@media only screen and (min-width: 1000px) {
	.wrap {
		margin: 0 70px;
	}
}
@media only screen and (min-width: 1280px) {
	.wrap {
		margin: 0 auto;
		width: 1060px;
	}
}
@media only screen and (min-width: 1900px) {
	html {
		font-size: 20px;
	}
	.wrap {
		width: 1420px;
	}
}
