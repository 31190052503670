.divider {
  hr {
    margin: 40px 0;
    border-top: 1px solid #ffffff;
    width: 100%;

    @media only screen and (min-width: 600px) {
      margin: 50px 0;
    }

    @media only screen and (min-width: 1280px) {
      margin: 50px 0;
    }

    @media only screen and (min-width: 1900px) {
      margin: 75px 0;
    }
  }
}