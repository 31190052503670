header {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: 0 0 0 0 rgba(0,0,0,0);
  transition: all .6s;

  &.not-top {
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.08);
    transition: all .6s;
  }

  @media only screen and (min-width: 1000px) {
    height: 100px;
  }

  #navMainHamburger {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;

    @media only screen and (min-width: 1000px) {
      display: none;
    }
  }

  a.brand {
    display: block;
    z-index: 3;
    white-space: nowrap;
    overflow: hidden;

	img {
		display: block;
		width: auto;
		height: 33px;
	}

    @media only screen and (min-width: 1000px) {
	  margin-left: 15px;
	  img {
		  height: 50px;
	  }
    }
  }

  nav#navMain {
    margin: 0 auto;

    .nav-main {
      list-style: none;
      padding: 0;

      li.nav-main-item {
        .nav-main-link {
          padding: 5px 0;

          &:hover {
            padding-bottom: 4px;
            border-bottom: 1px solid #1a2a36;
          }
        }
      }
    }

    @media only screen and (max-width: 999px) {
      position: fixed;
      display: none;
      color: inherit;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: white;

      .nav-main {
        text-align: center;
      }

      li.nav-main-item {
        margin: 30px 15px;
        font-weight: 100;
        font-size: 1.7rem;
        text-align: center;
      }

      &.visible {
        display: flex;
      }
    }

    @media only screen and (min-width: 1000px) {
      .nav-main {
        display: flex;
        flex-direction: row;

        li.nav-main-item {
          margin: 5px 15px;

          &::last-of-type {
            margin: 5px 60px 5px 15px;
          }
        }
      }
    }
  }
}
