.fullwidth-img {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center;
  min-height: 80vh;

  h3 {
    padding: 100px 24px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
  }

  .white {
    color: #fff;
  }
}
