/* 
.wrap {
	margin: 0 24px;
}
@media only screen and (min-width: 760px) {
	.wrap {
		margin: 0 50px;
	}
}
@media only screen and (min-width: 1280px) {
	.wrap {
		margin: 0 auto;
		width: 1180px;
	}
}
@media only screen and (min-width: 1900px) {
	.wrap {
		width: 1640px;
	}
}
*/


.art-cols {
	position: relative;
}

.art-col-1 {
  margin: 24px auto;
  padding: 0 24px;
}

.art-cols__col {
	margin: 24px 0;
}

.art-cols__img {
	display: block;
	width: 100%;
}

@media only screen and (min-width: 760px) {

}
@media only screen and (min-width: 1000px) {
  .art-col-1 {
    margin: 40px auto;
    max-width: 824px;
  }
	.art-cols {
		display: flex;
		justify-content: space-between;
		
    &.align-items-center {
      align-items: center;
    }
	}
	.art-cols__col {
		margin: 0;
		width: 40%;
		width: calc(50% - 35px);
	}
	.art-cols__col--right {
		order: 2;
	}
	.art-cols__col__text {
		width: 80%;

    &--left {
      float: right;
    }
	}
  .art-cols__col_3 {
    margin: 0;
    width: 33%;
    width: calc(33% - 35px);
  }
}

@media only screen and (min-width: 1280px) {

}

@media only screen and (min-width: 1900px) {

  .art-col-1 {
    max-width: 1093px;
  }

	.art-cols__col {
		width: 40%;
		width: calc(50% - 45px);
	}
  .art-cols__col_3 {
    margin: 0;
    width: 33%;
    width: calc(33% - 35px);
  }
}