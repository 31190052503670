/* 
.wrap {
	margin: 0 24px;
}
@media only screen and (min-width: 760px) {
	.wrap {
		margin: 0 50px;
	}
}
@media only screen and (min-width: 1280px) {
	.wrap {
		margin: 0 auto;
		width: 1180px;
	}
}
@media only screen and (min-width: 1900px) {
	.wrap {
		width: 1640px;
	}
}
 */


.art-fullpage {
	position: relative;
	width: 100%;
	height: 100vh;
  min-height: 500px;
}

.art-fullpage__landscape,
.art-fullpage__portrait {
	width: 100%;
	height: 100%;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}
.art-fullpage__landscape--opaque,
.art-fullpage__portrait--opaque {
	opacity: 0.6;
}

.art-fullpage__landscape__bg {
	width: 100%;
	height: 100%;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

.art-fullpage__landscape__bg--w2000 {
	display: none;
}

.art-fullpage__landscape__bg--w1400 {
	display: none;
}

.art-fullpage__landscape__bg--w1000 {
	display: block;
}

.art-fullpage__content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1.4em;
}

.art-fullpage__content--white {
	color: #fff;
}

.art-fullpage__content--white p {
	font-weight: 400;
}

.art-fullpage__content__wrap {
	position: relative;
	margin: 0 24px;
	max-width: 510px;
	text-align: center;
}

.art-fullpage__content__tagline {
	font-weight: 500;
	font-size: 0.8rem;
	text-transform: uppercase;
	letter-spacing: 0.25em;
}

.art-fullpage__content__headline {
	font-size: 2.5rem;
	line-height: 1em;
	letter-spacing: 0.03em;
	font-weight: 500;
}

.art-fullpage__content__headline-graphik {
	text-transform: uppercase;
	letter-spacing: 0.2em;
	font-size: 1rem;
	line-height: 1.3em;
	font-weight: 500;
}

.art-fullpage__discover {
	position: absolute;
	display: block;
	width: 100px;
	bottom: 2.5em;
	left: 50%;
	margin-left: -50px;
	font-size: 0.65rem;
	text-transform: uppercase;
	font-weight: 400;
	letter-spacing: 0.24em;
	text-align: center;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.205' height='14.456' viewBox='0 0 14.205 14.456'%3E%3Cg id='Group_205' data-name='Group 205' transform='translate(-1008.146 -1035)'%3E%3Cline id='Line_5' data-name='Line 5' y2='14' transform='translate(1015.2 1035)' fill='none' stroke='%231a2a36' stroke-width='1'/%3E%3Cpath id='Path_421' data-name='Path 421' d='M-3968.995,5108.5l6.749,6.749,6.749-6.749' transform='translate(4977.495 -4066.5)' fill='none' stroke='%231a2a36' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: auto 1em;
	padding-bottom: 1em;
}

.art-fullpage__discover--white {
	color: #fff;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.205' height='14.456' viewBox='0 0 14.205 14.456'%3E%3Cg id='Group_205' data-name='Group 205' transform='translate(-1008.146 -1035)'%3E%3Cline id='Line_5' data-name='Line 5' y2='14' transform='translate(1015.2 1035)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3Cpath id='Path_421' data-name='Path 421' d='M-3968.995,5108.5l6.749,6.749,6.749-6.749' transform='translate(4977.495 -4066.5)' fill='none' stroke='%23fff' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");
}

@media all and (orientation: landscape) {
	.art-fullpage__landscape {
		display: block;
	}
	.art-fullpage__portrait {
		display: none;
	}
}
@media all and (orientation: portrait) {
	.art-fullpage__landscape {
		display: none;
	}
	.art-fullpage__portrait {
		display: block;
	}
}
@media only screen and (min-width: 360px) {
	.art-fullpage__content__headline {
		font-size: 3rem;
	}
}
@media only screen and (min-width: 1000px) {
	.art-fullpage__content__headline-graphik {
		font-size: 1.5rem;
	}
}
@media only screen and (min-width: 1280px) {

}
@media only screen and (min-width: 1400px) {
	.art-fullpage__landscape__bg--w1400 {
		display: block;
	}
	.art-fullpage__landscape__bg--w1000 {
		display: none;
	}
}
@media only screen and (min-width: 1900px) {
	.art-fullpage__content__wrap {
		max-width: 680px;
	}
}
@media only screen and (min-width: 2000px) {
	.art-fullpage__landscape__bg--w2000 {
		display: block;
	}
	.art-fullpage__landscape__bg--w1400 {
		display: none;
	}
}