/* 
.wrap {
	margin: 0 24px;
}
@media only screen and (min-width: 760px) {
	.wrap {
		margin: 0 50px;
	}
}
@media only screen and (min-width: 1280px) {
	.wrap {
		margin: 0 auto;
		width: 1180px;
	}
}
@media only screen and (min-width: 1900px) {
	.wrap {
		width: 1640px;
	}
}
*/

.art-text {
	position: relative;
	margin-top: 60px;
	margin-bottom: 60px;
}

.art-text h2 {
	font-family: "Lyon Display";
	font-size: 2.5rem;
	line-height: 1.25em;
	font-weight: 700;
}

.art-text__wrap {
	margin: 0 24px;
	max-width: 520px;
}

.art-text__tagline {
	font-weight: 500;
	font-size: 0.8rem;
	text-transform: uppercase;
	letter-spacing: 0.25em;
	color: #A3A3A3;
}

@media only screen and (min-width: 568px) {
	.art-text {
		margin-top: 100px;
		margin-bottom: 100px;
	}
	.art-text__wrap {
		margin: 0 auto;
		margin: 0 auto 0 calc((100% - 520px) / 2.2);
	}
}
@media only screen and (min-width: 1280px) {
	.art-text {
		margin-top: 150px;
		margin-bottom: 150px;
	}
}
@media only screen and (min-width: 1900px) {
	.art-text {
		margin-top: 190px;
		margin-bottom: 190px;
	}
	.art-text__wrap {
		max-width: 685px;
		margin: 0 auto;
		margin: 0 auto 0 calc((100% - 685px) / 2.2);
	}
}